package ui

import com.intellij.generator.web.api.Target
import com.intellij.generator.web.api.Target.Option.Variant
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.*
import web.cssom.AlignItems
import web.cssom.Padding
import web.cssom.px

external interface TargetOptionsProps : Props {
    var target: Target
    var targetOptionId: Target.Option.Id
    var targetOption: Target.Option
    var selectedVariants: StateInstance<Set<Variant.Id>>
}

val TargetOptionsComponent = FC<TargetOptionsProps> { props ->
    val option = props.targetOption
    val multiOption = option.variants.size > 1
    var selectedVariants by props.selectedVariants
    Stack {
        id = "option" + props.target.name + props.targetOptionId.name

        option.name?.let {
            Typography {
                variant = TypographyVariant.subtitle1
                +parse(it, SafeParserOptions)
            }
        }

        FormControl {
            if (option.single) {
                if (!multiOption) {
                    Typography {
                        sx {
                            padding = Padding(8.px, 0.px)
                        }
                        variant = TypographyVariant.subtitle1
                        +parse(option.variants.single().name, SafeParserOptions)
                    }
                } else {
                    RadioGroup {
                        for (opt in option.variants) {
                            Stack {
                                direction = responsive(StackDirection.row)
                                sx {
                                    alignItems = AlignItems.center
                                }

                                FormControlLabel {
                                    id = "enableVariant" + props.target.name + props.targetOptionId.name + opt.id.name
                                    name = ""
                                    label = parse(opt.name, SafeParserOptions)
                                    disabled = !opt.enabled
                                    control = Radio.create()
                                    checked = opt.id in selectedVariants
                                    onChange = { _, isChecked ->
                                        if (isChecked) selectedVariants = setOf(opt.id)
                                    }
                                }
                                opt.tags.forEach { tag ->
                                    Chip {
                                        sx {
                                            marginLeft = (-8).px
                                            marginRight = 16.px
                                        }
                                        label = ReactNode(tag)
                                        size = Size.small
                                        variant = ChipVariant.outlined
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                FormGroup {
                    for (opt in option.variants) {
                        FormControlLabel {
                            id = (if (opt.id in selectedVariants) "disable" else "enable") + "Variant" + props.target.name + props.targetOptionId.name + opt.id.name
                            label = parse(opt.name, SafeParserOptions)
                            name = ""
                            disabled = !opt.enabled
                            control = Checkbox.create()
                            checked = opt.id in selectedVariants
                            onChange = { _, isChecked ->
                                selectedVariants = if (isChecked) {
                                    selectedVariants + opt.id
                                } else {
                                    selectedVariants - opt.id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}